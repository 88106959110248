import { Auth0Client, User, createAuth0Client } from "@auth0/auth0-spa-js";
import { FeatureFlags } from "./featureFlags";

const config = {
  domain: process.env.auth0Domain,
  clientId: process.env.auth0Client
};

export class Login {
  private static isLogged = false;
  private static user: User;

  private static auth0Client: Auth0Client = null;

  static configureClient = async () => {
    this.auth0Client = await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin
      },
      cacheLocation: "localstorage"
    });
  };

  static login = async () => {
    await this.auth0Client.loginWithRedirect();
  };

  static logout = async () => {
    //FeatureFlags.reset();
    await this.auth0Client.logout();
  };

  static isAuthentificated = async (): Promise<boolean> => {
    if (await this.auth0Client.isAuthenticated()) return true;

    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      await this.auth0Client.handleRedirectCallback();

      window.history.replaceState({}, document.title, "/");

      return await this.isAuthentificated();
    }
    return false;
  };

  static recoverLogin = async (): Promise<string | undefined> => {
    if (!(await this.isAuthentificated())) return;
    const user = await this.auth0Client.getUser();
    this.user = user;
    await FeatureFlags.fromEmail(user.email);
    this.isLogged = user ? true : false;

    return this.user.nickname;
  };

  static isUserLogged = (): boolean => this.isLogged;
}
