/* eslint-disable @typescript-eslint/no-explicit-any */
import { parse } from "papaparse";
import { hideLoader, showLoader } from "../html/loader";

export const readCsv = async (fileName: string): Promise<any> => {
  /* eslint-disable no-console */
  console.debug(fileName);
  showLoader();
  let text = await fetch(fileName).then((res) => res.text());
  text = text.trim();
  const csvData = parse(text, { header: true, dynamicTyping: true });
  hideLoader();
  return csvData;
};
