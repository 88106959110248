import {
  getHtmlDivElement,
  getHtmlElement,
  getHtmlInputElement,
  getHtmlOutputElement,
  getHtmlSpanElement,
  getHtmlTableRowElement,
  getSelectedOptionFor,
  setSelected
} from "../html/elements";
import { LocaleKeys, months, translate, units } from "../i18N/translate";
import { Blue, Green, Grey, Orange, Red, Yellow } from "../map/colors";
import { round } from "../utils/math";
import {
  Forecast,
  HTML_SELECTS,
  getDateFromWeek,
  getFilenameForLegend,
  getLeadTimeValue,
  getMaxValueFrom,
  getMinValueFrom,
  getSelectedDateFor
} from "../utils/queries";
import {
  ModelVariables,
  isForecastVariable,
  isIndexVariable,
  isParcelVariable,
  isStatsVariable
} from "../utils/variables";

const LOCALES = LocaleKeys();

export const setMaxLeadTime = (max: number): void => {
  const select = getHtmlInputElement(HTML_SELECTS.LeadTime);
  if (Number(select.value) > max) {
    select.value = max.toString();
  }

  select.max = max.toString();

  const element = getHtmlOutputElement("leadTimeValue");
  element.textContent = select.value;
};

export const setGradientLegend = (colors: string[]): void => {
  const gradientSpan = getHtmlSpanElement("gradient-legend-span");
  gradientSpan.style.width = "500px";
  gradientSpan.style.background = `linear-gradient(90deg, ${colors.join(",")})`;
};

export const setMaxGradientLegend = (value: number, units: string) => {
  const div = getHtmlDivElement("gradient-max");
  div.textContent = `${round(value, 1)} ${units}`;
};

export const setMinGradientLegend = (value: number, units?: string) => {
  const div = getHtmlDivElement("gradient-min");
  div.textContent = `${round(value, 1)}${units ? " " + units : ""}`;
};

export const setLegendVisibilityForHumidity = async (): Promise<void> => {
  const tercilesLegend = getHtmlDivElement("terciles-legend");
  const gradientLegend = getHtmlDivElement("gradient-legend");
  const forecastLegend = getHtmlDivElement("forecast-legend");

  tercilesLegend.style.display = "none";
  gradientLegend.style.display = "flex";
  forecastLegend.style.display = "none";

  setMaxGradientLegend(100, "%");
  setMinGradientLegend(0);
  setGradientLegend([Grey.White, Grey.Black]);
};

export const setLegendVisibility = async (
  variable: ModelVariables,
  forceHidden = false
): Promise<void> => {
  const tercilesLegend = getHtmlDivElement("terciles-legend");
  const gradientLegend = getHtmlDivElement("gradient-legend");
  const forecastLegend = getHtmlDivElement("forecast-legend");

  tercilesLegend.style.display = "none";
  gradientLegend.style.display = "none";
  forecastLegend.style.display = "none";

  if (forceHidden) return;

  const forecast = getSelectedOptionFor(HTML_SELECTS.Forecast);
  if (
    forecast === Forecast.Seasonal &&
    isForecastVariable(variable) &&
    !isStatsVariable(variable) &&
    !isParcelVariable(variable)
  )
    tercilesLegend.style.display = "flex";
  else {
    const filename = getFilenameForLegend();
    const isIndex = isIndexVariable(variable);
    const max = isIndex ? 1 : await getMaxValueFrom(filename);
    const min = isIndex ? 0 : await getMinValueFrom(filename);
    if (max === -999) return;
    setMaxGradientLegend(max, units[variable]);
    setMinGradientLegend(min);
    gradientLegend.style.display = "flex";
    switch (variable) {
      case ModelVariables.Precipitation:
      case ModelVariables.Wind:
        setGradientLegend([Grey.White, Blue.Intense]);
        break;
      case ModelVariables.Temperature:
      case ModelVariables.MinTemperature:
      case ModelVariables.MaxTemperature:
      case ModelVariables.SolarRadiation:
        setGradientLegend([Blue.Medium, Yellow.Medium, Orange.Base]);
        break;
      case ModelVariables.Evaporation:
      case ModelVariables.Humidity:
        setGradientLegend([Blue.Celeste, Blue.Intense]);
        break;
      case ModelVariables.GrossIrrigationNeeds:
      case ModelVariables.NetIrrigationNeeds:
      case ModelVariables.NetIrrigationNeedsPlotCubicMeters:
      case ModelVariables.NetIrrigationNeedsMillimeters:
        setGradientLegend([Green.Base, Green.Intense]);
        break;
      case ModelVariables.CropWaterStress:
      case ModelVariables.CropWaterStressPlot:
        setGradientLegend([Red.Intense, Yellow.Intense, Green.Intense]);
        break;
      case ModelVariables.Flow:
      case ModelVariables.Inflow:
        break;
      default:
        setGradientLegend([Grey.White, Blue.Intense]);
    }
  }

  let content = "";
  const leadTime = getLeadTimeValue();
  if (isForecastVariable(variable)) {
    switch (forecast) {
      case Forecast.Seasonal:
        const monthDate = new Date(
          `${getSelectedDateFor(HTML_SELECTS.Month)}-15`
        );
        const newMonthDate = new Date(monthDate);
        newMonthDate.setMonth(monthDate.getMonth() + leadTime);
        content = translate(
          LOCALES.forecastSeasonal,
          translate(months[newMonthDate.getMonth() + 1]).toLowerCase()
        );
        break;
      case Forecast.SubSeasonal:
        const weekDate = new Date(
          getDateFromWeek(getSelectedDateFor(HTML_SELECTS.Week))
        );
        weekDate.setDate(weekDate.getDate() + 7 * leadTime);
        content = translate(
          LOCALES.forecastSubSeasonal,
          weekDate.toLocaleDateString()
        );
        break;
      case Forecast.ShortTerm:
        const dayDate = new Date(getSelectedDateFor(HTML_SELECTS.Day));
        dayDate.setDate(dayDate.getDate() + leadTime);
        content = translate(
          LOCALES.forecastShortTerm,
          dayDate.toLocaleDateString()
        );
        break;
    }
  } else {
    const dayDate = new Date(getSelectedDateFor(HTML_SELECTS.Day));
    content = translate(LOCALES.historicalData, dayDate.toLocaleDateString());
  }

  forecastLegend.innerHTML = "<b>" + content + "</b>";
  forecastLegend.style.display = "flex";
};

export const setForecastVisibility = (isVisible: boolean): void => {
  const forecast = getHtmlElement("td-forecast");
  forecast.style.display = isVisible ? "flex" : "none";
};

export const setForecast = (forecastValue: string): void => {
  setSelected("forecast-select", forecastValue);
};

export const setDateVisibility = (forecast: string): void => {
  const month = getHtmlInputElement(HTML_SELECTS.Month);
  const week = getHtmlInputElement(HTML_SELECTS.Week);
  const day = getHtmlInputElement(HTML_SELECTS.Day);

  month.style.display = "none";
  week.style.display = "none";
  day.style.display = "none";

  switch (forecast) {
    case Forecast.Seasonal:
      month.style.display = "flex";
      break;
    case Forecast.SubSeasonal:
      week.style.display = "flex";
      break;
    case Forecast.ShortTerm:
    case "":
      day.style.display = "flex";
      break;
  }
};

export const setLeadTimeVisibility = (isVisible: boolean): void => {
  const leadTime = getHtmlElement("td-lead-time");
  leadTime.style.display = isVisible ? "flex" : "none";
};

export const setSourceVisibility = (forecast: string): void => {
  const origenRow = getHtmlTableRowElement("td-source");
  origenRow.style.display = forecast !== Forecast.Seasonal ? "none" : "flex";
};

export const setLeadTimeDescription = (): void => {
  const forecast = getSelectedOptionFor(HTML_SELECTS.Forecast);
  const leadTimeDescription = getHtmlDivElement("lead-time-description");
  const leadTime = getLeadTimeValue();

  let description = "months";
  switch (forecast) {
    case Forecast.Seasonal:
      description = leadTime === 1 ? "month" : "months";
      break;
    case Forecast.SubSeasonal:
      description = leadTime === 1 ? "week" : "weeks";
      break;
    case Forecast.ShortTerm:
      description = leadTime === 1 ? "day" : "days";
      break;
  }
  leadTimeDescription.innerHTML = `&nbsp;${translate(LOCALES[description])}`;
};

export const setSelectVisibility = (): void => {
  getHtmlDivElement("select").style.display = "flex";
};
