import { LocaleKeys, translate } from "../i18N/translate";

const LOCALES = LocaleKeys();

export const getHtmlElement = (id: string): HTMLElement => {
  const element = document.getElementById(id) as HTMLElement;
  if (!element) warn(id);
  return element;
};

export const getHtmlDivElement = (id: string): HTMLDivElement => {
  return getHtmlElement(id) as HTMLDivElement;
};

export const getHtmlOptionElement = (id: string): HTMLOptionElement => {
  return getHtmlElement(id) as HTMLOptionElement;
};

export const getHtmlSpanElement = (id: string): HTMLSpanElement => {
  return getHtmlElement(id) as HTMLSpanElement;
};

export const getHtmlInputElement = (id: string): HTMLInputElement => {
  return getHtmlElement(id) as HTMLInputElement;
};

export const getHtmlInputValue = (id: string): string => {
  const element = getHtmlInputElement(id);
  return element.value;
};

export const getHtmlOutputElement = (id: string): HTMLOutputElement => {
  return getHtmlElement(id) as HTMLOutputElement;
};

export const getHtmlOptGroupElement = (id: string): HTMLOptGroupElement => {
  return getHtmlElement(id) as HTMLOptGroupElement;
};

export const getHtmlTableRowElement = (id: string): HTMLTableRowElement => {
  return getHtmlElement(id) as HTMLTableRowElement;
};

export const getHtmlSelectElement = (id: string): HTMLSelectElement => {
  const element = getHtmlElement(id) as HTMLSelectElement;
  if (!element || !element.options) warn(id);
  return element;
};

export const getSelectedOptionFor = (id: string): string => {
  const select = getHtmlSelectElement(id);
  return select.value;
};

export const setContent = (id: string, content: string): void => {
  const element = getHtmlElement(id);
  if (element) element.textContent = content;
};

export const setInnerHtml = (id: string, innerHTML: string): void => {
  const element = getHtmlElement(id);
  if (element) element.innerHTML = innerHTML;
};

export const setOption = (id: string, text: string): void => {
  const option = getHtmlOptionElement(id);
  if (option) option.text = text;
};

export const setSelected = (id: string, text: string): void => {
  const option = getHtmlSelectElement(id);
  if (option) option.value = text;
};

export const setLabel = (id: string, label: string): void => {
  const option = getHtmlOptGroupElement(id);
  if (option) option.label = label;
};

export const setTitle = (id: string, title: string): void => {
  const div = getHtmlDivElement(id);
  if (div) div.title = title;
};

const warn = (id: string, position = -1): void => {
  position === -1
    ? console.warn(translate(LOCALES.warnNotPresent, id))
    : console.warn(
        translate(LOCALES.warnNotPresentWithPosition, id, position.toString())
      );
};
