export enum Grey {
  White = "#FFFFFF",
  Black = "#000000",
  Base = "#7f7f7f"
}

export enum Red {
  Intense = "#FF0000",
  Base = "#EDA8A8",
  Light = "#F5D7D7",
  UltraLight = "#FCEBEB"
}

export enum Yellow {
  Intense = "#FFC000",
  Base = "#F0F03A",
  Medium = "#FFE699",
  Light = "#FEE599",
  UltraLight = "#FFF2CC"
}

export enum Blue {
  Intense = "#0000FF",
  Base = "#5FA6E3",
  Medium = "#9BC2E6",
  Light = "#BDD7EE",
  UltraLight = "#DEEBF6",
  Celeste = "#00ccff"
}

export enum Green {
  Intense = "#247a12",
  Base = "#b8f7ab"
}

export enum Orange {
  Base = "#F4B084"
}
