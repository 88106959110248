import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { Layer, PopupOptions } from "leaflet";
import { LocaleKeys, translate } from "../i18N/translate";
import { getColor } from "./styles";
import { round } from "../utils/math";
import { readCsv } from "../tools/csv";
import { getCsvPath } from "../utils/queries";

const LOCALES = LocaleKeys();
const DECIMALS = 2;

const createPopupOptions = (
  latitude: number,
  longitude: number
): PopupOptions => ({
  closeButton: false,
  className: `lat:${latitude},lng:${longitude}`
});

const attachPopupToLayer = (
  layer: Layer,
  content: string,
  feature: Feature
): void => {
  layer.bindPopup(
    content,
    createPopupOptions(
      feature.properties.latitude,
      feature.properties.longitude
    )
  );
};

// Función para crear una ventana emergente
const createPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer,
  content: string
): void => {
  attachPopupToLayer(layer, content, feature);
};

export const getPopupOptions = (feature: Feature): PopupOptions =>
  createPopupOptions(feature.properties.latitude, feature.properties.longitude);

export const showPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  const { properties } = feature;
  const color = getColor(feature);
  const coordinates = isProduction() ? "" : getCoordinatesMessage(properties);
  const successRateText =
    properties.SuccessRate !== undefined
      ? `<br><br><b>${translate(LOCALES.successRate)}</b>: N/A`
      : "";
  const lower = `<b>${translate(LOCALES.lower)}</b>: ${round(
    properties.Lower * 100,
    DECIMALS
  )} %`;
  const medium = `<br><b>${translate(LOCALES.medium)}</b>: ${round(
    properties.Medium * 100,
    DECIMALS
  )} %`;
  const upper = `<br><b>${translate(LOCALES.upper)}</b>: ${round(
    properties.Upper * 100,
    DECIMALS
  )} %`;
  const popupContent = `<div style='height:10px;background:${color};'></div>${coordinates}${lower}${medium}${upper}${successRateText}`;
  if (hasCoordinates(properties)) createPopup(feature, layer, popupContent);
};

export const showPopupDrought = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  const { properties } = feature;
  const color = getColor(feature);
  const coordinates = isProduction() ? "" : getCoordinatesMessage(properties);
  const successRateText =
    properties.SuccessRate !== undefined
      ? `<br><br><b>${translate(LOCALES.successRate)}</b>: N/A`
      : "";
  const lower = `<b>${translate(LOCALES.lowerDrought)}</b>: ${round(
    properties.Lower * 100,
    DECIMALS
  )} %`;
  const medium = `<br><b>${translate(LOCALES.mediumDrought)}</b>: ${round(
    properties.Medium * 100,
    DECIMALS
  )} %`;
  const upper = `<br><b>${translate(LOCALES.upperDrought)}</b>: ${round(
    properties.Upper * 100,
    DECIMALS
  )} %`;
  const popupContent = `<div style='height:10px;background:${color};'></div>${coordinates}${lower}${medium}${upper}${successRateText}`;
  if (hasCoordinates(properties)) createPopup(feature, layer, popupContent);
};

export const showStationPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  if (!hasCoordinates(feature.properties)) return;
  const coordinates = isProduction()
    ? ""
    : getCoordinatesMessage(feature.properties);
  createPopup(
    feature,
    layer,
    `${coordinates}<b>${translate(LOCALES.name)}</b>: ${
      feature.properties.name
    }`
  );
};

export const showStatsPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  if (!hasCoordinates(feature.properties)) return;
  const coordinates = isProduction()
    ? ""
    : getCoordinatesMessage(feature.properties);
  const name = buildNameContent(feature);
  createPopup(
    feature,
    layer,
    `${coordinates}${name}<b>${translate(LOCALES.mean)}</b>: ${round(
      feature.properties.mean,
      DECIMALS
    )}`
  );
};

export const showShortTermPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  if (!hasCoordinates(feature.properties)) return;
  const coordinates = isProduction()
    ? ""
    : getCoordinatesMessage(feature.properties);
  const name = buildNameContent(feature);
  createPopup(
    feature,
    layer,
    `${coordinates}${name}<b>${translate(LOCALES.forecast)}</b>: ${round(
      feature.properties.value,
      DECIMALS
    )}`
  );
};

export const showNonForecastPopup = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  if (hasCoordinates(feature.properties) && feature.properties.value !== -999) {
    const coordinates = isProduction()
      ? ""
      : getCoordinatesMessage(feature.properties);
    const name = buildNameContent(feature);
    createPopup(
      feature,
      layer,
      `${coordinates}${name}<b>${translate(LOCALES.value)}</b>: ${round(
        feature.properties.value,
        DECIMALS
      )}`
    );
  }
};

export const showFishPopup = async (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  const table = await readCsv(getCsvPath("habitat_table_01"));
  const rnd = table.data[round(table.data.length * Math.random() - 1)];
  let content = "";
  Object.keys(rnd).forEach(
    (e) => (content += `${makeBold(e)}:  ${rnd[e]}${addBreakLine()}`)
  );
  createPopup(feature, layer, content);
};

const hasCoordinates = (properties: GeoJsonProperties): boolean => {
  return (
    properties &&
    properties.latitude !== undefined &&
    properties.longitude !== undefined
  );
};

const isProduction = (): boolean => {
  return process.env.NODE_ENV === "production";
};

const getCoordinatesMessage = (properties: GeoJsonProperties): string => {
  return createBreakLines([
    `${makeBold(translate(LOCALES.latitude))}:${properties.latitude}`,
    `${makeBold(translate(LOCALES.longitude))}: ${properties.longitude}`,
    ""
  ]);
};

const buildNameContent = (
  feature: Feature<Geometry, GeoJsonProperties>
): string => {
  if (feature.properties.name)
    return `${makeBold(translate(LOCALES.name))}: ${
      feature.properties.name
    }${addBreakLine()}`;

  //console.warn("No name in feature: ", feature);
  return "";
};

const makeBold = (text: string): string => `<b>${text}</b>`;
const addBreakLine = (): string => `</br>`;
const createBreakLines = (args: string[]): string => {
  let result = "";
  args.forEach((a) => (result += `${a}${addBreakLine()}`));
  return result;
};
