export const englishMessages = {
  accept: "Accept",
  anticipationPeriod: "Predicted Period",
  attributionGVA: `The INtegrated FORecasting System for Water and the Environment 
      (WATER4CAST) project has been funded by the Program for the promotion of scientific 
      research, technological development, and innovation in the Valencian Community for 
      groups of research excellence, PROMETEO 2021 (ref: PROMETEO/2021/074), of the Ministry 
      of Innovation, Universities, Science, and Digital Society of the Valencian Government.`,
  attributionIGN:
    "PNOA WMS. Provided by © National Geographic Institute of Spain",
  attributionSCN: "National Cartographic System",
  basins: "Hydrographic Basins",
  credits: "Credits",
  cropWaterStress: "Water Stress",
  cropWaterStressPlot: "Water Stress",
  cumPlotTitleSeasonal: "Cumulative Seasonal Prediction",
  cumPlotTitleShortTerm: "Cumulative Short-Term Prediction",
  cumPlotTitleSubSeasonal: "Cumulative Sub-Seasonal Prediction",
  day: "day",
  days: "days",
  drought: "Drought Indicator Prediction",
  droughtIndexesSpi: "Drought Index SPI",
  droughtIndexesSpei: "Drought Index SPEI",
  evaporation: "Reservoir Evaporation Rate",
  fires: "FWI",
  fireIndexes: "Fire Indicator Prediction",
  flow: "Flow",
  forecast: "Prediction",
  forecastAgricultural: "UDA Agricultural Prediction",
  forecastDate: "Prediction Date",
  forecastEco: "Ecohydrological Prediction",
  forecastHabitat: "Fish Habitat Prediction",
  forecastManagement: "Reservoir Management Prediction",
  forecastMeteorological: "Meteorological Prediction",
  forecastParcel: "Parcel Agricultural Prediction",
  forecastShortTerm: "Prediction for {0}",
  forecastSeasonal: "Prediction for the Month of {0}",
  forecastSubSeasonal: "Prediction for the Week of {0}",
  grossIrrigationNeeds: "Gross Irrigation Needs {0}",
  historicalData: "Data for {0}",
  helpCenter: "Help center",
  humidity: "Soil Humidity",
  indexes: "Indicators",
  inflow: "Reservoir Inflows",
  inflows: "Inflows",
  invalidUsername: "Incorrect username or password",
  latitude: "Latitude",
  license: `WATER4CAST contains information modified by IIAMA-UPV from the Copernicus 
      Climate Change Service. Neither the European Commission nor the ECMWF is responsible 
      for the use of Copernicus information or data contained therein.`,
  login: "Log In...",
  logout: "Log Out",
  longitude: "Longitude",
  lower: "Lower than Normal",
  lowerDrought: "Drought period",
  mean: "Average Value",
  medium: "Around Normal",
  mediumDrought: "Normal Period",
  month: "month",
  monthApril: "April",
  monthAugust: "August",
  monthDecember: "December",
  monthFebruary: "February",
  monthJanuary: "January",
  monthJuly: "July",
  monthJune: "June",
  monthMarch: "March",
  monthMay: "May",
  monthNovember: "November",
  monthOctober: "October",
  months: "months",
  monthSeptember: "September",
  name: "Name",
  netIrrigationNeeds: "Net Irrigation Needs {0}",
  noDataAvailable: "No data available for the current selection",
  others: "Others:",
  outflows: "Outflows",
  password: "Password",
  plotTitleSeasonalCoordinate:
    "Seasonal Prediction with model {0}<br> coordinates ({1}° , {2}°) - {3}",
  plotTitleSeasonal: "Seasonal Prediction",
  plotTitleShortTerm: "Short-Term Prediction",
  plotTitleSubSeasonal: "Sub-Seasonal Prediction",
  potentialHabitat: "Potential Habitat for Native Fish Species",
  precipitation: "Precipitation",
  readMore: "Read More...",
  seasonal: "Seasonal",
  sessionStart: "Session Start",
  shortTerm: "Short-Term",
  solarRadiation: "Solar Radiation",
  source: "Source",
  startSession: "Start Session",
  storedVolume: "Reservoir Volume",
  subSeasonal: "Sub-Seasonal",
  successRate: "Historical Accuracy",
  spi6: "SPI 6 months",
  spi12: "SPI 12 months",
  spi18: "SPI 18 months",
  spi24: "SPI 24 months",
  spei6: "SPEI 6 months",
  spei12: "SPEI 12 months",
  spei18: "SPEI 18 months",
  spei24: "SPEI 24 months",
  temperature: "Temperature",
  temperatureMax: "Maximum Temperature",
  temperatureMin: "Minimum Temperature",
  terrain: "Terrain",
  upper: "Higher than Normal",
  upperDrought: "Humid period",
  username: "Username",
  value: "Value",
  variable: "Variable",
  warnNotPresent: "Id '{0}' not present in the document",
  warnNotPresentWithPosition:
    "Position '{0}' not present in Select element with id '{1}'",
  week: "week",
  weeks: "weeks",
  welcome: "Welcome, <b>{0}</b>",
  wind: "Wind",
  withoutQuality: "Without Quality",
  withoutQualityDrought: "Without Indicator",
  year: "Year"
};

export type LocaleMessage = { [key in keyof typeof englishMessages]: string };
