import {
  getHtmlDivElement,
  getHtmlInputElement,
  getHtmlOptGroupElement,
  getHtmlOutputElement,
  getHtmlSelectElement,
  setLabel,
  setOption,
  setTitle
} from "../html/elements";
import { LocaleKeys, translate } from "../i18N/translate";
import { FeatureFlags } from "../tools/featureFlags";
import { Login } from "../tools/login";
import { HTML_SELECTS } from "../utils/queries";
import { ModelVariables } from "../utils/variables";
const LOCALES = LocaleKeys();

export const addListeners = (): void => {
  addLeadTimeListeners();
  addHelpListeners();
  addCreditsListeners();
  addLoginListeners();
};

const addLeadTimeListeners = (): void => {
  const value = getHtmlOutputElement("leadTimeValue");
  const input = getHtmlInputElement(HTML_SELECTS.LeadTime);
  value.textContent = input.value;
  input.addEventListener("input", () => {
    value.textContent = input.value;
  });
};

const addHelpListeners = (): void => {
  const help = getHtmlDivElement("help");
  const closeButton = getHtmlDivElement("close-button");

  closeButton.addEventListener("click", () => {
    help.style.display = "none";
  });
  const helpShadow = getHtmlDivElement("help-shadow");
  helpShadow.addEventListener("click", () => {
    help.style.display = "none";
  });
};

const addCreditsListeners = (): void => {
  const help = getHtmlDivElement("help");
  const credits = getHtmlDivElement("credits");
  const attributionGVA = LOCALES.attributionGVA;
  credits.addEventListener("click", () => {
    setHelpPopupStyle({
      width: "50%",
      height: "auto",
      top: "25%",
      left: "25%"
    });
    help.style.display = "flex";
    const content = `<a target="blank"><img src="./img/logoGVA.png" height="150px"/></a><div>${translate(
      attributionGVA
    )}</div>`;
    setHelpContent(help, content);
  });
};

const addLoginListeners = (): void => {
  const loginSection = getHtmlDivElement("login");
  loginSection.addEventListener("click", async () => {
    if (await Login.isAuthentificated()) {
      Login.logout();
    } else {
      await Login.login();
    }
  });
};

const addLoggedFeatures = async (): Promise<void> => {
  const indexesGroup = getHtmlOptGroupElement("drought-indexes-variable-spi");

  if (FeatureFlags.isEnabled("ssrd")) {
    const ssrd = document.createElement("option");
    ssrd.id = "variable-ssrd";
    ssrd.value = ModelVariables.SolarRadiation;
    getHtmlOptGroupElement("meteorological-variable").append(ssrd);
    setOption("variable-ssrd", translate(LOCALES.solarRadiation));
  }

  if (FeatureFlags.isEnabled("wind")) {
    const wind = document.createElement("option");
    wind.id = "variable-wind";
    wind.value = ModelVariables.Wind;
    getHtmlOptGroupElement("meteorological-variable").append(wind);
    setOption("variable-wind", translate(LOCALES.wind));
  }

  if (FeatureFlags.isEnabled("parcel")) {
    const parcelGroup = document.createElement("optgroup");
    parcelGroup.id = "agricultural-parcel-variable";
    getHtmlSelectElement(HTML_SELECTS.Variable).insertBefore(
      parcelGroup,
      indexesGroup
    );
    setLabel(parcelGroup.id, translate(LOCALES.forecastParcel));

    const nIrrigationNeedsm3 = document.createElement("option");
    nIrrigationNeedsm3.id = "variable-nirrigneeds-parcel-m3";
    nIrrigationNeedsm3.value = "ninpm3";
    getHtmlOptGroupElement(parcelGroup.id).append(nIrrigationNeedsm3);
    setOption(
      nIrrigationNeedsm3.id,
      translate(LOCALES.netIrrigationNeeds, "m³")
    );

    const nIrrigationNeedsmm = document.createElement("option");
    nIrrigationNeedsmm.id = "variable-nirrigneeds-parcel-mm";
    nIrrigationNeedsmm.value = "ninpmm";
    getHtmlOptGroupElement(parcelGroup.id).append(nIrrigationNeedsmm);
    setOption(
      nIrrigationNeedsmm.id,
      translate(LOCALES.netIrrigationNeeds, "mm")
    );

    const crop = document.createElement("option");
    crop.id = "variable-cws-parcel";
    crop.value = ModelVariables.CropWaterStressPlot;
    getHtmlOptGroupElement(parcelGroup.id).append(crop);
    setOption(crop.id, translate(LOCALES.cropWaterStressPlot));
  }

  if (FeatureFlags.isEnabled("habitat")) {
    const habitatGroup = document.createElement("optgroup");
    habitatGroup.id = "habitat-variable";
    getHtmlSelectElement(HTML_SELECTS.Variable).insertBefore(
      habitatGroup,
      indexesGroup
    );
    setLabel("habitat-variable", translate(LOCALES.forecastHabitat));

    const habPotUtil = document.createElement("option");
    habPotUtil.id = "variable-habPotUtil";
    habPotUtil.value = "habPotUtil";
    getHtmlOptGroupElement(habitatGroup.id).append(habPotUtil);
    setOption(habPotUtil.id, translate(LOCALES.potentialHabitat));
  }

  if (FeatureFlags.isEnabled("management")) {
    const managementGroup = document.createElement("optgroup");
    managementGroup.id = "management-variable";
    getHtmlSelectElement(HTML_SELECTS.Variable).insertBefore(
      managementGroup,
      indexesGroup
    );
    setLabel("management-variable", translate(LOCALES.forecastManagement));

    const volEmb = document.createElement("option");
    volEmb.id = "variable-volEmb";
    volEmb.value = "volEmb";
    getHtmlOptGroupElement(managementGroup.id).append(volEmb);
    setOption(volEmb.id, translate(LOCALES.storedVolume));

    const inflows = document.createElement("option");
    inflows.id = "variable-inflows";
    inflows.value = "inflows";
    getHtmlOptGroupElement(managementGroup.id).append(inflows);
    setOption(inflows.id, translate(LOCALES.inflows));

    const outflows = document.createElement("option");
    outflows.id = "variable-outflows";
    outflows.value = "outflows";
    getHtmlOptGroupElement(managementGroup.id).append(outflows);
    setOption(outflows.id, translate(LOCALES.outflows));
  }
};

const setLoginUser = (user: string): void => {
  getHtmlDivElement("login-text").innerHTML = translate(LOCALES.welcome, user);
  setTitle("login", translate(LOCALES.logout));
};

const setHelpContent = (help: HTMLDivElement, content: string) => {
  help.style.display = "flex";
  const helpContent = getHtmlDivElement("help-content");
  helpContent.innerHTML = content;
};

const setHelpPopupStyle = ({
  width = "auto",
  height = "auto",
  top = "25%",
  left = "25%",
  right = "auto"
}: {
  width: string;
  height: string;
  top: string;
  left: string;
  right?: string;
}): void => {
  const helpPopup = getHtmlDivElement("help-popup");

  helpPopup.style.width = width;
  helpPopup.style.height = height;
  helpPopup.style.top = top;
  helpPopup.style.left = left;
  helpPopup.style.right = right;
};

export const recoverLogin = async (): Promise<void> => {
  const user = await Login.recoverLogin();
  if (user) {
    setLoginUser(user);
    addLoggedFeatures();
  }
};
