export function showLoader() {
  if (!document.getElementById("overlay")) return;
  document.getElementById("overlay").style.display = "block";
  document.getElementById("loader").style.display = "block";
}

export function hideLoader() {
  if (!document.getElementById("overlay")) return;
  document.getElementById("overlay").style.display = "none";
  document.getElementById("loader").style.display = "none";
}
